import { useEffect, useRef, useState, FC } from "react";
import { useLocation } from "react-router-dom";
import { Autoplay } from "swiper/modules";
import { Swiper as SwiperComponent, SwiperSlide } from "swiper/react";
import Modal from "react-bootstrap/Modal";
import PageContextClass from "../tools/PageContextClass";
import ScrollAnimation from "../tools/ScrollAnimation";
import { getPageContent } from "../tools/LayoutContent";
import { Header } from "../components/Header";
import { Footer } from "../components/Footer";
import imagesLoaded from "imagesloaded";
import Masonry from "react-masonry-css";
import { PreLoader } from "../components/PreLoader";
import HeroDecal from "../assets/hero_decal.svg";
import TargetDecal from "../assets/target_decal.svg";
import Logo from "../assets/logo_black.svg";
import FooterImage from "../assets/news_post_footer.jpg";
import "../styles//Media.scss";

interface DataState {
  sharedData: any;
  pageData: any;
}

interface PostState {
  message: any;
  isReady: boolean;
}

const MediaPage: FC = () => {
  const { pathname } = useLocation();
  const InitEffect = useRef(false);
  const [pageContent, setPageData] = useState<DataState>({
    sharedData: {},
    pageData: {},
  });
  const [modalVisible, setModalVisible] = useState<boolean>(false);
  const [post, setPost] = useState<PostState>({
    message: {},
    isReady: false,
  });

  const fetchPostData = async (id: number) => {
    try {
      const responds = await getPageContent(`post_${id}`, {
        post: `wp/v2/news/${id}`,
      });
      if (responds.post) {
        setPost({ message: responds.post, isReady: true });
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const handleOpenModal = async (
    id: number,
    event: React.MouseEvent<HTMLAnchorElement, MouseEvent>
  ) => {
    event.preventDefault();
    fetchPostData(id);
    setModalVisible(true);
  };

  const handleCloseModal = () => {
    setModalVisible(false);
    setPost({ message: {}, isReady: false });
  };

  const breakpointColumnsObj = {
    default: 2,
    600: 1,
  };

  useEffect(() => {
    const ScrollController = new ScrollAnimation();
    const Init = () => {
      window.scrollTo(0, 0);
      try {
        fetchData();
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    const fetchData = async () => {
      try {
        const pageContentClass = new PageContextClass(pathname);
        const responds = await pageContentClass.fetchData();
        if (responds && responds.sharedData && responds.pageData) {
          setPageData(responds);
          startProcess();
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    const startProcess = () => {
      const imgLoad = imagesLoaded("#root", { background: true });
      imgLoad.on("done", () => {
        setTimeout(function () {
          // Handle Inview Area
          ScrollController.ScrollAnim({
            element: "#app-nav",
            duration: "100%",
            trigger: 0.7,
            target: "#app-nav .inview",
          });
          ScrollController.ScrollAnim({
            element: "#app-media",
            duration: "100%",
            trigger: 0.7,
            target: "#app-media .inview",
          });
        }, 500);
      });
    };
    if (process.env.NODE_ENV === "development") {
      if (InitEffect.current) {
        const cleanup = Init();
        return cleanup;
      }
      return () => {
        InitEffect.current = true;
      };
    } else {
      Init();
    }
  }, [pathname]);
  return (
    <div id="app-pages">
      <script
        src="https://cdn.jsdelivr.net/npm/masonry-layout@4.2.2/dist/masonry.pkgd.min.js"
        integrity="sha384-GNFwBvfVxBkLMJpYMOABq3c+d3KnQxudP/mGPkzpZSTYykLBNsZEnG2D9G/X/+7D"
        crossOrigin="anonymous"
        async
      ></script>

      <div
        id="app-loader"
        className={`collapse ${pageContent.pageData.isReady ? "" : "show"}`}
      >
        <PreLoader />
      </div>
      <div
        id="app-media"
        className={`collapse fade ${
          pageContent.pageData.isReady ? "show" : ""
        }`}
      >
        <Header location={pathname} data={pageContent.sharedData} />
        <div className="draw-lines">
          <div
            className="line-overlay stroke-lines inview animated"
            data-ease="fadeIn"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="img-fluid"
              viewBox="0 0 448.94 448.94"
            >
              <g id="Layer_2" data-name="Layer 2">
                <g id="Layer_1-2" data-name="Layer 1">
                  <line x1="0.4" y1="448.54" x2="448.54" y2="0.4" />
                </g>
              </g>
            </svg>
          </div>
          <div
            className="hero-underlay-line stroke-lines inview animated"
            data-ease="fadeIn"
          >
            <svg
              className="img-fluid"
              xmlns="http://www.w3.org/2000/svg"
              x="0px"
              y="0px"
              viewBox="0 0 1278 1343"
            >
              <g>
                <defs>
                  <rect x="-100" y="-2.1" width="1378.6" height="1401.7" />
                </defs>
                <clipPath id="SVGID_2_">
                  <rect
                    x="-100.03"
                    y="-2.06"
                    width="1378.57"
                    height="1401.74"
                  />
                </clipPath>
                <g className="st0">
                  <polygon points="2.6,838.1 852.5,-18.1 1487.9,-18.1 810.1,667.6 810.1,1341.1 316,841.2" />
                  <polygon points="885.3,1339.8 885.3,708.1 1186,405.1 1815.2,405.1" />
                </g>
              </g>
            </svg>
          </div>
        </div>
        <div className="decal-overlay-top">
          <div className="inview animated delay-1" data-ease="fadeInLeft">
            <img
              src={HeroDecal}
              className="img-fluid"
              width="411"
              height="536"
              alt="Hero Decal Header"
            />
          </div>
        </div>
        <div
          className="decal-overlay inview animated delay-1"
          data-ease="fadeInLeft"
        >
          <img
            src={HeroDecal}
            className="img-fluid"
            width="411"
            height="536"
            alt="Hero Decal Header"
          />
        </div>
        <div className="decal-overlay-bottom">
          <img
            id="target-img"
            src={TargetDecal}
            className="img-fluid"
            width="54"
            height="48"
            alt="Down Decal"
          />
        </div>
        <section id="news" className="container-fluid offset-top">
          <Masonry
            breakpointCols={breakpointColumnsObj}
            className="masonry-grid"
            columnClassName="masonry-grid-column"
          >
            {pageContent.pageData.isReady &&
              pageContent.pageData.message.media &&
              pageContent.pageData.message.media.map(
                (news: any, index: number) => (
                  <div className={`col grid-item`} key={index}>
                    <div className="card h-100">
                      {news.acf.slider ? (
                        <div className="card-image scale-off">
                          <SwiperComponent
                            loop
                            modules={[Autoplay]}
                            autoplay={{
                              delay: 3000,
                              disableOnInteraction: false,
                            }}
                          >
                            {news.acf.slider.map(function (
                              slide: any,
                              index: number
                            ) {
                              return (
                                <SwiperSlide key={index}>
                                  <a
                                    href={`${news.acf.link.url}`}
                                    target="_blank"
                                    rel="noreferrer"
                                  >
                                    <img
                                      className="img-fluid"
                                      src={slide}
                                      alt={news.title.rendered}
                                    />
                                  </a>
                                </SwiperSlide>
                              );
                            })}
                          </SwiperComponent>
                        </div>
                      ) : !news.acf.link ? (
                        <a
                          href={`#${news.id}`}
                          onClick={(event) => handleOpenModal(news.id, event)}
                        >
                          {" "}
                          {news.acf.main_image ? (
                            <div className="card-image">
                              <img
                                src={news.acf.main_image.url}
                                className="img-fluid"
                                alt={news.title.rendered}
                              />
                            </div>
                          ) : (
                            <div className="card-image holder">
                              <img
                                src={Logo}
                                className="img-fluid"
                                alt={news.title.rendered}
                              />
                            </div>
                          )}
                        </a>
                      ) : (
                        <a
                          href={`${news.acf.link.url}`}
                          target="_blank"
                          rel="noreferrer"
                        >
                          {news.acf.main_image ? (
                            <div className="card-image">
                              <img
                                src={news.acf.main_image.url}
                                className="img-fluid"
                                alt={news.title.rendered}
                              />
                            </div>
                          ) : (
                            <div className="card-image holder">
                              <img
                                src={Logo}
                                className="img-fluid"
                                alt={news.title.rendered}
                              />
                            </div>
                          )}
                        </a>
                      )}

                      <div className="card-wrapper">
                        {!news.acf.link ? (
                          <a
                            href={`#${news.id}`}
                            onClick={(event) => handleOpenModal(news.id, event)}
                          >
                            <h4 className="card-title cake">
                              {news.title.rendered}
                            </h4>
                          </a>
                        ) : (
                          <a
                            href={`${news.acf.link.url}`}
                            target="_blank"
                            rel="noreferrer"
                          >
                            <h4 className="card-title cake">
                              {news.title.rendered}
                            </h4>
                          </a>
                        )}
                      </div>
                    </div>
                  </div>
                )
              )}
          </Masonry>
        </section>
        <Footer data={pageContent.sharedData} />
      </div>
      <div id="modal-block" className="collapse fade">
        <Modal size="xl" show={modalVisible} onHide={handleCloseModal}>
          <Modal.Header closeButton />
          <Modal.Body>
            {post.isReady && (
              <>
                <div className="header-image">
                  <img
                    src={post.message.acf.main_image.url}
                    className="img-fluid"
                    alt={post.message.title.rendered}
                  />
                </div>
                <div className="article-content">
                  <h1 className="cake">{post.message.title.rendered}</h1>
                  <div
                    className="content-block"
                    dangerouslySetInnerHTML={{
                      __html: post.message.content.rendered,
                    }}
                  />
                  <div className="draw-lines">
                    <div className="line-overlay stroke-lines inview animated fadeIn">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="img-fluid"
                        viewBox="0 0 448.94 448.94"
                      >
                        <g id="Layer_2" data-name="Layer 2">
                          <g id="Layer_1-2" data-name="Layer 1">
                            <line x1="0.4" y1="448.54" x2="448.54" y2="0.4" />
                          </g>
                        </g>
                      </svg>
                    </div>
                    <div className="hero-underlay-line stroke-lines inview animated fadeIn">
                      <svg
                        className="img-fluid"
                        xmlns="http://www.w3.org/2000/svg"
                        x="0px"
                        y="0px"
                        viewBox="0 0 1278 1343"
                      >
                        <g>
                          <defs>
                            <rect
                              x="-100"
                              y="-2.1"
                              width="1378.6"
                              height="1401.7"
                            />
                          </defs>
                          <clipPath id="SVGID_2_">
                            <rect
                              x="-100.03"
                              y="-2.06"
                              width="1378.57"
                              height="1401.74"
                            />
                          </clipPath>
                          <g className="st0">
                            <polygon points="2.6,838.1 852.5,-18.1 1487.9,-18.1 810.1,667.6 810.1,1341.1 316,841.2" />
                            <polygon points="885.3,1339.8 885.3,708.1 1186,405.1 1815.2,405.1" />
                          </g>
                        </g>
                      </svg>
                    </div>
                  </div>
                  <div className="decal-overlay-bottom">
                    <img
                      id="target-img"
                      src={TargetDecal}
                      className="img-fluid"
                      width="54"
                      height="48"
                      alt="Down Decal"
                    />
                  </div>
                </div>
              </>
            )}
            <div className="footer-image">
              <img src={FooterImage} className="img-fluid" alt="emptyvessel" />
            </div>
          </Modal.Body>
        </Modal>
      </div>
    </div>
  );
};

export default MediaPage;
