import { getPageContent } from "./LayoutContent";
import jsonSharedData from "../json/shared.json";
import jsonPagesData from "../json/pages.json";

interface JsonData {
  [key: string]: string;
}

class PageContextClass {
  private cleanPathName: string;
  private pagesEndpoints: any = jsonPagesData;
  private sharedEndpoints: JsonData = jsonSharedData;

  constructor(pathname: string) {
    this.cleanPathName = pathname.replace(/\//g, "").toLowerCase();
  }

  fetchData = async () => {
    try {
      if (!(this.cleanPathName in this.pagesEndpoints)) {
        this.cleanPathName = "";
      }
      const keyPage = Object.keys(this.pagesEndpoints[this.cleanPathName])[0];
      const shared = await getPageContent("shared", this.sharedEndpoints);
      const contentPage = await getPageContent(
        keyPage,
        this.pagesEndpoints[this.cleanPathName]
      );
      return {
        sharedData: {
          header: shared.header,
          footer: shared.footer,
          social: shared.social,
          isReady: true,
        },
        pageData: { message: contentPage, isReady: true },
      };
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
}

export default PageContextClass;
