import { useEffect, useRef, FC, useState } from "react";
import { useLocation } from "react-router-dom";
import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
import GLightbox from "glightbox";
import { Navigation } from "swiper/modules";
import { Swiper as SwiperComponent, SwiperSlide } from "swiper/react";
import SvgDefectLogo from "../components/SvgDefectLogo";
import { Header } from "../components/Header";
import { PreLoader } from "../components/PreLoader";
import { GameFooter } from "../components/GameFooter";
import imagesLoaded from "imagesloaded";
import ScrollAnimation from "../tools/ScrollAnimation";
import PageContextClass from "../tools/PageContextClass";
import HeroRightDecal from "../assets/defect/defect_right_bars.png";
import HeroDecal from "../assets/defect/hero_decal.svg";
import RxtBar from "../assets/defect/02-rxt-bars.png";
import OecpBar from "../assets/defect/oecp_bar.png";
import CommunityBar from "../assets/defect/communty_bar.png";
import TrailerPlayBtn from "../assets/defect/defect_playbtn.png";
import TrailerHeadline from "../assets/defect/trailer_headline.png";
import TrailerVideoImage from "../assets/defect/defect_trailer.jpg";
import SignupArrows from "../assets/defect/signup_arrows.png";

import TargetDecal from "../assets/target_decal.svg";
import SwiperArrows from "../assets/defect/swiper_arrows.svg";

import BkgImageStory from "../assets/defect/story_bkg.jpg";

import "../styles/Defect.scss";
import { Social } from "../components/Social";
import Newsletter from "../components/Newsletter";
import TextComponent from "../components/TextComponent";

gsap.registerPlugin(ScrollTrigger);

interface DataState {
  sharedData: any;
  pageData: any;
}

const DefectPage: FC = () => {
  const { pathname } = useLocation();
  const InitEffect = useRef(false);
  const videoRef = useRef<HTMLIFrameElement>(null);
  const [showVideo, setShowVideo] = useState<boolean>(false);
  const [pageContent, setPageData] = useState<DataState>({
    sharedData: {},
    pageData: {},
  });
  const handlePayBtn = () => {
    setShowVideo(true);
  };

  useEffect(() => {
    const ScrollController = new ScrollAnimation();
    const Init = () => {
      window.scrollTo(0, 0);
      fetchData();
    };

    const fetchData = async () => {
      try {
        const pageContentClass = new PageContextClass(pathname);
        const responds = await pageContentClass.fetchData();
        if (responds && responds.sharedData && responds.pageData) {
          setPageData(responds);
          startProcess();
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    const startProcess = () => {
      const elementId = document.getElementById("gallery");
      const imgLoad = imagesLoaded("#root", { background: true });
      imgLoad.on("done", () => {
        if (elementId) {
          // Handle Inview Area
          ScrollController.ScrollAnim({
            element: "#app-nav",
            duration: "100%",
            trigger: 0.7,
            target: "#app-nav .inview",
          });
          ScrollController.ScrollAnim({
            element: "#hero",
            duration: "100%",
            trigger: 0.7,
            target: "#hero .inview",
          });
          ScrollController.ScrollAnim({
            element: "#about",
            duration: "100%",
            trigger: 0.7,
            target: "#about .inview",
          });
          ScrollController.ScrollAnim({
            element: "#gallery",
            duration: "100%",
            trigger: 0.7,
            target: "#gallery .inview",
          });
          gsap
            .timeline({ scrollTrigger: { trigger: "#about", scrub: true } })
            .fromTo(
              "#about-zoom",
              { scale: "1.2" },
              { scale: "1", ease: "none" },
              "100%"
            );
          gsap
            .timeline({ scrollTrigger: { trigger: "#trailer", scrub: true } })
            .fromTo(
              "#trailer-zoom",
              { scale: "1" },
              { scale: "0.8", ease: "none" },
              "100%"
            );

          GLightbox({ moreLength: 0 });
        } else {
          setTimeout(startProcess, 1000);
        }
      });
    };
    if (process.env.NODE_ENV === "development") {
      if (InitEffect.current) {
        const cleanup = Init();
        return cleanup;
      }
      return () => {
        InitEffect.current = true;
      };
    } else {
      Init();
    }
  }, [pathname]);
  return (
    <div id="app-pages">
      <div
        id="app-loader"
        className={`collapse ${pageContent.pageData.isReady ? "" : "show"}`}
      >
        <PreLoader />
      </div>
      {pageContent.pageData.isReady &&
        pageContent.pageData.message.defect[0] &&
        pageContent.pageData.message.defect[0].acf && (
          <div
            id="app-defect"
            className={`collapse fade ${
              pageContent.pageData.isReady ? "show" : ""
            }`}
          >
            <Header
              theme="light"
              location={pathname}
              data={pageContent.sharedData}
            />
            <div id="hero">
              <div className="video-wrapper">
                <video
                  className="video-embed"
                  muted
                  loop
                  autoPlay
                  preload="auto"
                >
                  <source
                    src="/assets/videos/Defect_EmptyVessel_Hero.mp4"
                    type="video/mp4"
                  />
                </video>
              </div>
              <div
                className="decal-overlay inview animated delay-1"
                data-ease="fadeInLeft"
              >
                <img
                  src={HeroDecal}
                  className="img-fluid"
                  width="510"
                  height="664"
                  alt="Hero Decal Header"
                />
              </div>
              <div className="decal-overlay-right inview animated delay-1">
                <img
                  src={HeroRightDecal}
                  className="img-fluid"
                  width="50"
                  height="270"
                  alt="Hero Decal Header"
                />
              </div>
              <div className="caption container h-100">
                <div className="d-flex align-items-center justify-content-center h-100">
                  <div className="caption-wrapper">
                    <SvgDefectLogo fillColor={`rgb(241, 52, 51)`} />

                    <div className="content-wrapper">
                      <h1 className="cake inview animated fast text-uppercase">
                        {
                          pageContent.pageData.message.defect[0].acf
                            .headline_title
                        }
                      </h1>
                      <div className="inview animated fast delay-2">
                        <TextComponent
                          text={
                            pageContent.pageData.message.defect[0].acf
                              .headline_description
                          }
                        />
                      </div>
                      <div className="inview animated fast delay-2 mt-4">
                        <a
                          className="btn btn-theme cake"
                          href="https://store.steampowered.com/app/2470010/DEFECT/"
                          target="_blank"
                          rel="noreferrer"
                        >
                          Wishlist Now
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div id="trailer">
              <div className="headline-block">
                <img
                  src={TrailerHeadline}
                  className="img-fluid w-100"
                  width="328"
                  height="66"
                  alt="Defect - When Control Fails, Chaos Reigns"
                />
                <h2 className="red">TRAILER</h2>
              </div>
              <div id="trailer-zoom">
                <div className="ratio ratio-16x9 text-center">
                  {!showVideo && (
                    <div className="placeholder-img" onClick={handlePayBtn}>
                      <img
                        loading="lazy"
                        className="img-fluid w-100"
                        src={TrailerVideoImage}
                        width="1920"
                        height="1080"
                        alt="Defect - When Control Fails, Chaos Reigns"
                      />
                    </div>
                  )}
                  {showVideo && (
                    <iframe
                      ref={videoRef}
                      className="embed-responsive-item"
                      width="560"
                      height="315"
                      src="https://www.youtube.com/embed/IScUN3ZYeR8?autoplay=1"
                      title="Main Video"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                      referrerPolicy="strict-origin-when-cross-origin"
                      allowFullScreen
                    ></iframe>
                  )}
                </div>
                {!showVideo && (
                  <div className="play-btn-wrapper" onClick={handlePayBtn}>
                    <div className="play-btn">
                      <img
                        loading="lazy"
                        className="img-fluid"
                        src={TrailerPlayBtn}
                        width="190"
                        height="190"
                        alt="Defect - When Control Fails, Chaos Reigns"
                      />
                    </div>
                  </div>
                )}
              </div>
            </div>
            <div id="about">
              <div
                id="about-zoom"
                className="bkg-zoom"
                style={{ backgroundImage: `url(${BkgImageStory})` }}
              ></div>
              <div
                id="rxt-bar"
                className="inview animated delay-2"
                data-ease="fadeInRight"
              >
                <img
                  className="img-fluid"
                  src={RxtBar}
                  alt="Defect - When Control Fails, Chaos Reigns"
                />
              </div>
              <div
                id="oecp-bar"
                className="inview animated delay-1"
                data-ease="fadeInLeft"
              >
                <img
                  className="img-fluid"
                  src={OecpBar}
                  alt="Defect - When Control Fails, Chaos Reigns"
                />
              </div>
              <div className="overlay d-flex align-items-center justify-content-center h-100">
                <div className="container">
                  <div className="block-container">
                    <h1 className="title-heading inview animated mb-4">
                      {pageContent.pageData.message.defect[0].acf.about_title}
                    </h1>
                    <h3
                      className="heading inview animated mb-4"
                      dangerouslySetInnerHTML={{
                        __html:
                          pageContent.pageData.message.defect[0].acf
                            .about_sub_title,
                      }}
                    />
                    <div className="inview animated delay-2">
                      <TextComponent
                        text={
                          pageContent.pageData.message.defect[0].acf
                            .about_description
                        }
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div id="community">
              <h2 className="d-md-flex justify-content-center">
                <div className="linebar">
                  <img
                    src={CommunityBar}
                    className="img-fluid"
                    width="237"
                    height="68"
                    alt="Down Decal"
                  />
                </div>
                <div className="text-nowrap">JOIN THE</div>
                <SvgDefectLogo fillColor={`rgb(255, 255, 255)`} />
                <div>COMMUNITY</div>
                <div className="linebar">
                  <img
                    src={CommunityBar}
                    className="img-fluid"
                    width="237"
                    height="68"
                    alt="Down Decal"
                  />
                </div>
              </h2>
              <div className="social-block">
                <Social data={pageContent.pageData.message.defect[0].acf} />
              </div>
            </div>
            <div id="gallery">
              <div className="px-3">
                <img
                  id="target-img"
                  src={TargetDecal}
                  className="img-fluid"
                  width="54"
                  height="48"
                  alt="Down Decal"
                />
                <h2 className="heading inview animated mb-5">
                  <span className="arrows">
                    <svg
                      width="32px"
                      height="40px"
                      className="img-fluid"
                      viewBox="0 0 32 40"
                    >
                      <path
                        fillRule="evenodd"
                        fill="rgb(255, 255, 255)"
                        d="M-0.000,19.992 L32.000,-0.008 L32.000,39.992 L-0.000,19.992 Z"
                      />
                    </svg>
                  </span>
                  <span className="red">SHOOTING</span> GALLERY
                  <span className="arrows">
                    <svg
                      width="32px"
                      height="40px"
                      className="img-fluid"
                      viewBox="0 0 32 40"
                    >
                      <path
                        fillRule="evenodd"
                        fill="rgb(241, 52, 51)"
                        d="M-0.000,19.992 L32.000,-0.008 L32.000,39.992 L-0.000,19.992 Z"
                      />
                    </svg>
                  </span>
                </h2>
              </div>
              <div id="gallery-slider">
                <SwiperComponent
                  loop
                  speed={800}
                  centeredSlides
                  initialSlide={1}
                  spaceBetween={60}
                  modules={[Navigation]}
                  onSlideChangeTransitionStart={() => {
                    const arrows = document.querySelectorAll(".swiper-arrow");
                    arrows?.forEach((arrow: any) => {
                      arrow.classList.add("opacity-0");
                    });
                  }}
                  onSlideChangeTransitionEnd={() => {
                    const arrows = document.querySelectorAll(".swiper-arrow");
                    arrows?.forEach((arrow: any) => {
                      arrow.classList.remove("opacity-0");
                    });
                  }}
                  onResize={() => {
                    const mediaMobileQuery =
                      window.matchMedia("(max-width: 768px)");
                    const mediaQuery = window.matchMedia("(max-width: 1280px)");
                    let offset = 40;
                    if (mediaMobileQuery.matches) {
                      offset = 20;
                    } else if (mediaQuery.matches) {
                      offset = 25;
                    }
                    const arrows = document.querySelectorAll(
                      ".swiper-arrow"
                    ) as NodeListOf<HTMLElement>;
                    const containerWidth = document.querySelector(
                      ".swiper-slide"
                    ) as HTMLElement;

                    if (arrows.length >= 2 && containerWidth) {
                      arrows[0].style.left = `calc(50% - ${
                        containerWidth.offsetWidth / 2 + offset
                      }px)`;
                      arrows[1].style.right = `calc(50% - ${
                        containerWidth.offsetWidth / 2 + offset
                      }px)`;
                    }
                  }}
                  navigation={{
                    prevEl: ".swiper-prev-arrow",
                    nextEl: ".swiper-next-arrow",
                  }}
                  breakpoints={{
                    0: {
                      slidesPerView: 1.3,
                      spaceBetween: 30,
                    },
                    767: {
                      slidesPerView: 2.2,
                      spaceBetween: 40,
                    },
                    1279: {
                      slidesPerView: 2.2,
                      spaceBetween: 60,
                    },
                  }}
                >
                  {pageContent.pageData.message.defect[0] &&
                    pageContent.pageData.message.defect[0].acf.gallery.map(
                      function (slide: any, index: number) {
                        return (
                          <SwiperSlide key={index}>
                            <a
                              href={slide.url}
                              className="glightbox"
                              data-gallery="Gallery"
                              data-type="image"
                            >
                              <img
                                className="img-fluid"
                                src={slide.url}
                                alt={slide.title}
                              />
                            </a>
                          </SwiperSlide>
                        );
                      }
                    )}
                  <div className="swiper-arrow swiper-prev-arrow">
                    <img
                      src={SwiperArrows}
                      className="img-fluid"
                      alt="Defect - When Control Fails, Chaos Reigns"
                    />
                  </div>
                  <div className="swiper-arrow swiper-next-arrow">
                    <img
                      src={SwiperArrows}
                      className="img-fluid"
                      alt="Defect - When Control Fails, Chaos Reigns"
                    />
                  </div>
                </SwiperComponent>
              </div>
            </div>
            <div id="signup">
              <div id="left-arrow-signup">
                <img
                  src={SignupArrows}
                  className="img-fluid"
                  alt="Defect - When Control Fails, Chaos Reigns"
                />
              </div>
              <div id="right-arrow-signup">
                <img
                  src={SignupArrows}
                  className="img-fluid"
                  alt="Defect - When Control Fails, Chaos Reigns"
                />
              </div>
              <Newsletter title="SIGN UP FOR NEWSLETTER" />
              <div className="mt-3">
                Subscribe to emptyvessel newsletter for exclusive gaming
                updates.
              </div>
            </div>
            <GameFooter
              color="rgb(241,52,51)"
              data={pageContent.sharedData}
              esrb={pageContent.pageData.message.defect[0].acf.esrb}
            />
          </div>
        )}
    </div>
  );
};

export default DefectPage;
