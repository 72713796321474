import { Component } from "react";
import { Link } from "react-router-dom";
import { Social } from "./Social";
import { ScrollToView } from "../tools/Helpers";
import SvgLogo from "./SvgLogo";

interface MenuMessage {
  url: string;
  title: string;
  attr_title: string;
  classes: any;
  target: string;
}
interface SocialMessage {
  link: string;
  name: string;
  class: string;
}
interface HeaderProps {
  theme?: string;
  location?: string;
  data: {
    header: MenuMessage[];
    social: SocialMessage[];
    isReady: boolean;
  };
}
export class Header extends Component<HeaderProps> {
  render() {
    const { header, isReady } = this.props.data;
    return (
      <>
        <nav
          id="app-nav"
          className={`navbar navbar-expand ${
            this.props.theme === "light" ? "light" : ""
          }`}
        >
          <div className="container-fluid">
            <div
              className="navbar-brand inview animated delay-2"
              data-ease="fadeInLeft"
            >
              <Link to="/">
                <SvgLogo
                  fillColor={
                    this.props.theme !== "light"
                      ? `rgb(0, 0, 0)`
                      : `rgb(255, 255, 255)`
                  }
                />
              </Link>
            </div>
            <div
              className="collapse navbar-collapse inview animated"
              data-ease="fadeInLeft"
            >
              <ul className="navbar-nav me-auto cake">
                {isReady &&
                  header.map((nav: any, index: number) => (
                    <li key={index} className="nav-item">
                      {nav.classes && nav.classes[0] === "scroll" ? (
                        <Link
                          className={`nav-link ${
                            this.props.location === nav.url ? "active" : ""
                          }`}
                          to={nav.url}
                          onClick={() => ScrollToView(nav.post_name)}
                        >
                          {nav.title}
                        </Link>
                      ) : (
                        <Link
                          className={`nav-link ${
                            this.props.location === nav.url ? "active" : ""
                          }`}
                          to={nav.url}
                        >
                          {nav.title}
                        </Link>
                      )}
                    </li>
                  ))}
              </ul>
              <div
                className="social-block inview animated delay-1"
                data-ease="fadeInRight"
              >
                <Social data={this.props.data} />
              </div>
            </div>
          </div>
        </nav>
      </>
    );
  }
}
