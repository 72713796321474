import { useEffect, useRef, FC, MouseEvent, useState } from "react";
import imagesLoaded from "imagesloaded";
import { Link, useLocation } from "react-router-dom";
import YouTubePlayer from "youtube-player";
import { Header } from "../components/Header";
import Contact from "../components/Contact";
import Newsletter from "../components/Newsletter";
import { Footer } from "../components/Footer";
import TextComponent from "../components/TextComponent";
import { PreLoader } from "../components/PreLoader";
import { ScrollToView } from "../tools/Helpers";
import HeroPose from "../assets/hero_pose.png";
import HeroDecal from "../assets/hero_decal.svg";
import DownDecal from "../assets/arrow_decal.png";
import LogoBlack from "../assets/logo_black.png";
import TargetDecal from "../assets/target_decal.svg";
import ScrollAnimation from "../tools/ScrollAnimation";
import PageContextClass from "../tools/PageContextClass";
import "../styles//Home.scss";
import { Social } from "../components/Social";
import { Modal } from "react-bootstrap";

interface DataState {
  sharedData: any;
  pageData: any;
}

const HomePage: FC = () => {
  const { pathname } = useLocation();
  const InitEffect = useRef(false);
  const [showTrailer, setShowTrailer] = useState<boolean>(false);
  const [pageContent, setPageData] = useState<DataState>({
    sharedData: {},
    pageData: {},
  });

  const HandleShowTrailer = (
    event: MouseEvent<HTMLButtonElement | HTMLAnchorElement>
  ) => {
    event.preventDefault();
    setShowTrailer(true);
  };

  const HandleCloseTrailer = () => setShowTrailer(false);

  const PlayTrailer = () => {
    const player = YouTubePlayer("player");
    const codeElement = document.getElementById("trailer-modal");
    if (codeElement) {
      const code = codeElement.dataset.code;
      if (code) {
        player.loadVideoById(code);
        player.playVideo();
      }
    }
  };

  useEffect(() => {
    const ScrollController = new ScrollAnimation();
    const Init = () => {
      window.scrollTo(0, 0);
      try {
        fetchData();
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    const fetchData = async () => {
      try {
        const pageContentClass = new PageContextClass(pathname);
        const responds = await pageContentClass.fetchData();
        if (responds && responds.sharedData && responds.pageData) {
          setPageData(responds);
          startProcess();
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    const startProcess = () => {
      const imgLoad = imagesLoaded("#root", { background: true });
      imgLoad.on("done", () => {
        // Handle Inview Area
        ScrollController.ScrollAnim({
          element: "#app-nav",
          duration: "100%",
          trigger: 0.7,
          target: "#app-nav .inview",
        }); // Header
        ScrollController.ScrollAnim({
          element: "#hero",
          duration: "100%",
          trigger: 0.7,
          target: "#hero .inview",
        });

        // Check if Auto Scroll to Contact
        if (pathname === "/contact/")
          setTimeout(function () {
            ScrollToView("contact");
          }, 500);
        else if (pathname === "/about/")
          setTimeout(function () {
            ScrollToView("about");
          }, 500);
      });
    };
    if (process.env.NODE_ENV === "development") {
      if (InitEffect.current) {
        const cleanup = Init();
        return cleanup;
      }
      return () => {
        InitEffect.current = true;
      };
    } else {
      Init();
    }
  }, [pathname]);
  return (
    <div id="app-pages">
      <div
        id="app-loader"
        className={`collapse ${pageContent.pageData.isReady ? "" : "show"}`}
      >
        <PreLoader />
      </div>
      <div
        id="app-home"
        className={`collapse fade ${
          pageContent.pageData.isReady ? "show" : ""
        }`}
      >
        <Header location={pathname} data={pageContent.sharedData} />
        <div id="hero">
          <div
            className="line-overlay stroke-lines inview animated slow"
            data-ease="fadeIn"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="img-fluid"
              viewBox="0 0 448.94 448.94"
            >
              <g id="Layer_2" data-name="Layer 2">
                <g id="Layer_1-2" data-name="Layer 1">
                  <line x1="0.4" y1="448.54" x2="448.54" y2="0.4" />
                </g>
              </g>
            </svg>
          </div>
          <div
            className="hero-underlay-line stroke-lines inview animated slow"
            data-ease="fadeIn"
          >
            <svg
              className="img-fluid"
              xmlns="http://www.w3.org/2000/svg"
              x="0px"
              y="0px"
              viewBox="0 0 1278 1343"
            >
              <g>
                <defs>
                  <rect x="-100" y="-2.1" width="1378.6" height="1401.7" />
                </defs>
                <clipPath id="SVGID_2_">
                  <rect
                    x="-100.03"
                    y="-2.06"
                    width="1378.57"
                    height="1401.74"
                  />
                </clipPath>
                <g className="st0">
                  <polygon points="2.6,838.1 852.5,-18.1 1487.9,-18.1 810.1,667.6 810.1,1341.1 316,841.2" />
                  <polygon points="885.3,1339.8 885.3,708.1 1186,405.1 1815.2,405.1" />
                </g>
              </g>
            </svg>
          </div>
          <div className="hero-overlay inview animated slow" data-ease="fadeIn">
            <img
              src={HeroPose}
              className="img-fluid"
              width="1274"
              height="1343"
              alt="Hero Header"
            />
          </div>
          <div className="down-overlay inview animated delay-1">
            <img
              src={DownDecal}
              className="img-fluid"
              width="1274"
              height="1343"
              alt="Down Decal"
            />
          </div>
          <div
            className="decal-overlay inview animated delay-1"
            data-ease="fadeInLeft"
          >
            <img
              src={HeroDecal}
              className="img-fluid"
              width="411"
              height="536"
              alt="Hero Decal Header"
            />
          </div>
          <div className="caption">
            <div className="d-flex align-items-end align-items-md-center justify-content-end h-100">
              <div className="caption-wrapper">
                {pageContent.pageData.isReady &&
                  pageContent.pageData.message.home &&
                  pageContent.pageData.message.home[0].acf && (
                    <>
                      <h1
                        className="cake inview animated fast"
                        data-ease="fadeInLeft"
                      >
                        {
                          pageContent.pageData.message.home[0].acf
                            .headline_title
                        }
                      </h1>
                      <h3
                        className="cake inview animated fast delay-1"
                        data-ease="fadeInLeft"
                      >
                        {
                          pageContent.pageData.message.home[0].acf
                            .headline_subtitle
                        }
                      </h3>
                      <div
                        className="inview animated fast delay-2"
                        data-ease="fadeInLeft"
                      >
                        <TextComponent
                          text={
                            pageContent.pageData.message.home[0].acf
                              .headline_description
                          }
                        />
                      </div>
                    </>
                  )}
                <div
                  className="inview animated fast delay-2"
                  data-ease="fadeInLeft"
                >
                  <Link
                    className="btn btn-theme btn-black cake me-2"
                    to="/"
                    onClick={() => ScrollToView("about")}
                  >
                    Learn More
                  </Link>
                  <a
                    className="btn btn-theme btn-black cake"
                    href="#trailer"
                    title="Play Trailer"
                    onClick={HandleShowTrailer}
                  >
                    WATCH DEFECT TRAILER
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div id="about" className="py-2">
          <div className="bkg-logo h-100 d-flex align-items-center">
            <img
              src={LogoBlack}
              className="img-fluid"
              data-ease="fadeInDown"
              width="1920"
              height="332"
              alt="Down Decal"
            />
          </div>
          <div className="wrapper">
            {pageContent.pageData.isReady &&
              pageContent.pageData.message.home &&
              pageContent.pageData.message.home[0].acf && (
                <h2 className="display-1 draw-line cake">
                  {pageContent.pageData.message.home[0].acf.about_title}
                </h2>
              )}
            <div>
              {pageContent.pageData.isReady &&
                pageContent.pageData.message.home &&
                pageContent.pageData.message.home[0].acf && (
                  <TextComponent
                    text={
                      pageContent.pageData.message.home[0].acf.about_description
                    }
                  />
                )}
            </div>
            <div className="target d-flex justify-content-end">
              <img
                id="target-img"
                src={TargetDecal}
                className="img-fluid"
                width="54"
                height="48"
                alt="Down Decal"
              />
            </div>
          </div>
        </div>
        <div id="contact">
          <div className="container-xl">
            <div id="contact-form" className="row">
              {pageContent.pageData.isReady &&
                pageContent.pageData.message.home &&
                pageContent.pageData.message.home[0].acf && (
                  <Contact
                    title={
                      pageContent.pageData.message.home[0].acf.contact_title
                    }
                  />
                )}
            </div>
            <div className="row">
              <div className="col-md-7"></div>
              <div className="col-md-5">
                {pageContent.pageData.isReady &&
                  pageContent.pageData.message.home &&
                  pageContent.pageData.message.home[0].acf && (
                    <Newsletter
                      title={
                        pageContent.pageData.message.home[0].acf
                          .newsletter_title
                      }
                      text={
                        pageContent.pageData.message.home[0].acf
                          .newsletter_description
                      }
                    />
                  )}
                <div className="social-block d-flex align-items-center">
                  <Social data={pageContent.sharedData} />
                  <span>FIND US ON SOCIAL</span>
                </div>
              </div>
            </div>
            <Footer data={pageContent.sharedData} />
          </div>
        </div>
      </div>
      {pageContent.pageData.isReady &&
        pageContent.pageData.message.home &&
        pageContent.pageData.message.home[0].acf && (
          <Modal
            id="trailer-modal"
            show={showTrailer}
            onHide={HandleCloseTrailer}
            onEntered={PlayTrailer}
            size="xl"
            centered
            data-code={
              pageContent.pageData.message.home[0].acf.headline_trailer_code
            }
          >
            <Modal.Header>
              <button
                type="button"
                className="btn-close btn-close-white"
                aria-label="Close"
                onClick={HandleCloseTrailer}
              ></button>
            </Modal.Header>
            <Modal.Body>
              <div className="ratio ratio-16x9">
                <div id="player" />
                {pageContent.pageData.message.home[0].acf.headline_trailer_code}
              </div>
            </Modal.Body>
          </Modal>
        )}
    </div>
  );
};

export default HomePage;
