import { FC } from "react";

interface SvgLogoProps {
  fillColor: string;
}

const SvgLogo: FC<SvgLogoProps> = ({ fillColor }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 2888.21 2147.92"
      className="img-fluid"
    >
      <path
        fill={fillColor}
        d="M2358.21,0c-2.31,3.13-4.26,6.62-7,9.34q-124.7,124.84-249.54,249.53L1290,1070.2c-2.83,2.82-5.48,5.86-8.5,8.45a11,11,0,0,0-3.93,9.73c.18,3,0,6,0,9V2142.2c-3.11-2.44-4.92-3.64-6.45-5.11-3.84-3.69-7.56-7.51-11.33-11.28Q877,1743.1,494.18,1360.3c-5.36-5.36-10.68-7.61-18.28-7.6q-231.48.3-463,.15H0c1.53-2.5,2-3.66,2.81-4.52,2.27-2.44,4.67-4.76,7-7.12Q676.24,675.12,1342.61,9c2.76-2.77,5.07-6,7.6-9Z"
      />
      <path
        fill={fillColor}
        d="M2888.21,665.69c-2.89,2.62-5.9,5.12-8.65,7.87q-733.87,733.49-1467.72,1467c-2.48,2.48-5,4.91-7.53,7.36l-1.92-.84v-9.57q0-493.19-.13-986.37c0-6.89,2.06-11.72,6.91-16.55Q1591,953.15,1772.65,771.46c33.23-33.2,66.68-66.2,99.67-99.65,5.61-5.69,11.14-8,19.1-8q496.92.3,993.83.21a29.62,29.62,0,0,0,3-.28Z"
      />
    </svg>
  );
};

export default SvgLogo;
