import { FC } from "react";

interface TextComponentProps {
  text?: string;
}

const TextComponent: FC<TextComponentProps> = ({ text }) => {
  // Split text into paragraphs
  const paragraphs: string[] =
    typeof text === "string"
      ? text.split("\n").filter((paragraph: string) => paragraph.trim() !== "")
      : [];

  // Render each paragraph inside <p> tags
  const renderedParagraphs: JSX.Element[] =
    paragraphs &&
    paragraphs.map((paragraph, index) => (
      <div key={index} dangerouslySetInnerHTML={{ __html: paragraph }} />
    ));

  // Render the component with the paragraphs
  return <>{renderedParagraphs}</>;
};

export default TextComponent;
